<template>
  <div class="utilityBillView">
    <div class="filter-panel">
      <CSSelect i-width="36rpx" style="width: 230px; margin-right: 0">
        <el-date-picker
          type="datetime"
          placeholder="请选择开始时间"
          v-model="conditions.startTime"
          style="width: 214px"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          prefix-icon="el-icon-time"
          :editable="false"
        >
        </el-date-picker>
      </CSSelect>
      <span class="inline-gray"> </span>
      <CSSelect i-width="36rpx" style="width: 230px">
        <el-date-picker
          type="datetime"
          placeholder="请选择结束时间"
          v-model="conditions.endTime"
          style="width: 214px"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          prefix-icon="el-icon-time"
          :editable="false"
        >
        </el-date-picker>
      </CSSelect>
      <CSSelect style="margin-left: 20px">
        <select v-model="conditions.contractState">
          <option value="">楼栋不限</option>
          <!-- <option
            v-for="(name, state) in contractStates"
            :key="state"
            :value="state"
          >
            {{ name }}
          </option> -->
        </select>
      </CSSelect>
      <div
        style="
          vertical-align: middle;
          margin-bottom: 15px;
          display: inline-block;
        "
      >
        <input
          type="text"
          placeholder="搜索业主/租客"
          v-model="conditions.queryInput"
          style="
            color: #000;
            width: 320px;
            height: 30px;
            padding-left: 10px;
            border-radius: 4px;
            border: 1px solid #979797;
            margin-right: 20px;
          "
        />
      </div>
      <button type="button" class="btn btn-primary btn-sm" @click="query()">
        查询
      </button>
    </div>

    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel">
            <span>已缴费面积:-</span>
            <span>未缴费面积：-</span>
            <span>已缴费面积占比：-</span>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>房间号</th>
            <th>所在楼栋</th>
            <th>所在楼层</th>
            <th>业主/租客</th>
            <th>建筑面积(m²)</th>
            <th>已缴费账单</th>
            <th>已缴费面积(m²)</th>
            <th>未缴费面积(m²)</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <!-- <tr>
            <td>租客</td>
            <td>计费标准</td>
            <td>账单周期</td>
            <td>物业计费期限</td>
            <td>应缴费用（元）</td>
            <td>附加费用（元）</td>
            <td>减免金额（元）</td>
            <td>实缴费用（元）</td>
          </tr> -->
        </template>
      </CSTable>
      <!-- //分页 -->
      <Pagination name="pagination" componentName="Pagination"></Pagination>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import CSSelect from "@/components/common/CSSelect";
import Pagination from "@/components/Pagination.vue";
import CSTable from "@/components/common/CSTable";
export default {
  data() {
    return {
      filterHeight: "",
      conditions: {
        startTime: dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00",
        endTime: dayjs().format("YYYY-MM-DD") + " 23:59",
        contractState: "", //账单状态
        queryInput: "", //房间查询信息
      },
    };
  },
  components: {
    CSSelect,
    Pagination,
    CSTable,
  },
  methods: {
    //查询按钮
    query() {},
  },
  mounted() {
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });

  },
};
</script>

<style scoped>
.btn-active {
  color: #1dafff;
  text-decoration: underline;
  cursor: pointer;
}
.table-header-panel span {
  margin-right: 30px;
}
</style>