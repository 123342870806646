<template>
  <div class="utill-container">
    <!-- 物业账单 -->
    <!-- <CSTabBar
      :tabbar="tabBar"
      :checked-tab="checkedTaBar"
      @changeTabBar="changeTabBar"
    /> -->
    <template v-if="checkedTaBar == 1">
       <UtilityBillView></UtilityBillView>
    </template>
    <template v-if="checkedTaBar == 2">
        <PaymentArea></PaymentArea>
    </template>
  </div>
</template>

<script>
import CSTabBar from "@/components/common/CSTabBar";
import dayjs from "dayjs";
import UtilityBillView from "@/views/UtilityBill/utilityBillView"
import PaymentArea from "@/views/UtilityBill/paymentArea.vue";

export default {
  data() {
    return {
      checkedTaBar: 1,
      tabBar: {
        1: "物业账单",
        2: "缴费面积统计",
      },
    };
  },
  components: {
    CSTabBar,
    UtilityBillView,
    PaymentArea
  },
  methods:{
      changeTabBar(index){
          this.checkedTaBar = +index;
      }
  }
};
</script>

<style>
</style>